<template>

    <div class="tw-flex-1 tw-flex-col tw-flex tw-overflow-auto">
        <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4 tw-pr-2">
            <PackageCardItem v-for="item in items.data" :key="item.id" :item="item" :allData="allData" :showViewAction="false" :showDeleteAction="false">
                <template #actionButton>
                    <Button class="tw-w-full btn-view" :data="{title: 'Apply', type: 'primary', event: 'apply', icon: 'fas fa-check'}" @apply="$emit('submit', item)"/>
                </template>
            </PackageCardItem>
        </div>
    </div>

    <!-- <div class="items" v-for="(pack) in items.data" :key="pack.id">
        <div class="items__title" @click="$emit('submit', pack)">
          {{ pack.name }}ssss
          <p class="items__description">{{pack.description}}</p>
        </div>
    </div> -->
</template>

<script>
import { ref } from 'vue'
import Item from './elements/Item'
import PackageCardItem from './../../components/modals/packages/PackageCardItem.vue'
import Button from './../form/Button.vue'

export default({
  components: {
    Item,
    PackageCardItem,
    Button,
  },
    props: ['items', 'allData'],
    setup(props) {
        const categories = ref([])
        getCategories()
        async function getCategories() {
            const array = []

            props.items.data.forEach(el => {
                const category = array.find(item => item.title == el.category)
                if(!category) {
                    array.push({title: el.category, items: []})
                    const lastItem = array[array.length - 1]
                    lastItem.items.push(el)
                } else category.items.push(el)

            })

            categories.value = array
        }
        return {
            categories
        }
    },
})
</script>


<style scoped lang="scss">
.items {
    font-family: Sansation;
    margin-bottom: 40px;
     &__title {
        font-size: 18px;
        line-height: 20px;
        text-transform: uppercase;
        color: #000000;
       margin-bottom: 30px;
       cursor: pointer;

       @media (max-width: 768px) {
           text-align: left;
           margin-bottom: 20px;
       }

    }

    &__description{
      font-size: 14px;
      text-transform: none;
      font-weight: normal;
      padding-left: 15px;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
}
::v-deep .item__title {
    max-width: 200px;
}
</style>
